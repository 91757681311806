import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import './AvatarStack.css'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles';

const StyledAv = withStyles({
  avatar: {
    width: 'calc(100%/5)',
    height: 'auto',
    fontSize: '2.5em'
  },
})(AvatarGroup)

class GroupAvatars extends React.Component {
  static defaultProps = {
    issues: [],
    mx: 7,
    start: 0
  }

  render() {
    const { issues, mx, start, final } = this.props
        if(final) {
          return(
            <Link to='/endorse/'>
            <StyledAv max={mx}>
            {!!issues && 
                issues.slice(start).map((issue) => (
                <Avatar key={issue.index} src={issue.featuredImage} alt={issue.title}/>
                )
            )}
            </StyledAv>
            </Link>
          )
        } else {
          return(
            <Link to='/endorse/'>
            <StyledAv max={mx}>
            {!!issues && 
                issues.slice(start,start+mx-1).map((issue) => (
                <Avatar key={issue.index} src={issue.featuredImage} alt={issue.title}/>
                )
            )}
            </StyledAv>
            </Link>
          )
        }
  }
}

export default GroupAvatars