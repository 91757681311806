import React from 'react'
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { Location } from '@reach/router'
import Donate from '../components/Donate'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import NavLink from '../components/Nav'
import BackgroundVideo from '../components/BackgroundVideo'
import GroupAvatars from '../components/AvatarStack'
import Image from '../components/Image'
import qs from 'qs'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ 
  featuredImage, 
  body, 
  donatevalues, 
  callaction, 
  callaction2, 
  video, 
  issues=[],
  enableSearch = false,
  contentType
}) => (
  <main className="Home">
        {/* <div className="calltoaction">
        <Content style={{ display:"inline", alignitems:"center"}} source={callaction} />
        <Link to="/getfacts"><button className="calltoactionButton">Get the facts</button></Link>
      </div> */}
<PageHeader
      large 
      backgroundImage={featuredImage}
    /> <div className="calltoaction">
    {/* <Content style={{ display:"inline", alignitems:"center"}}  source={callaction2}  /> */}
    {/* <Link to="/earlyvote"><button className="calltoactionButton">Find out here</button></Link> */}
  </div>
      {/* <Donate items={donatevalues} /> */}
      
      <div><BackgroundVideo>
        {video && <source src={video} type="video/mp4" />}
      </BackgroundVideo></div>

     <section className="section ">
     <div className="container">
      <div>
        <h3>Candace was endorsed by a large number of national and local leaders and organizations during the 2020 election cycle.  Candace is committed to continuing to honor their support through the work she does in her commmunity.</h3>
        <GroupAvatars issues={issues} start={0} mx={6}/>
        <GroupAvatars issues={issues} start={5} mx={6}/>
        <GroupAvatars issues={issues} start={10} mx={6}/>
        <GroupAvatars issues={issues} start={15} mx={6}/>
        <GroupAvatars issues={issues} start={20} mx={6}/>
        <GroupAvatars issues={issues} start={25} mx={6}/>
        <GroupAvatars issues={issues} start={30} mx={5} final/>
      </div>
      </div>      
     </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, issues } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate 
    {...page} 
    {...page.frontmatter}
    issues={issues.edges.map(issues => ({
      ...issues.node,
      ...issues.node.frontmatter,
      ...issues.node.fields
    }))}
    body={page.html}/>
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        featuredImage
        donatevalues
        callaction
        callaction2
        video
      }
    }

    issues: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "endorse" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage
          }
        }
      }
    }
  }
`
